// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "mdn-polyfills/NodeList.prototype.forEach";

const TOGGLES  = document.querySelectorAll("[data-toggle*=search]");

TOGGLES.forEach((toggle) => {
    const FORM  = document.getElementById(toggle.dataset.toggle);
    const INPUT = FORM ? FORM.querySelector("input[type=search]") : false;
    const ELEMS = FORM ? FORM.querySelectorAll("*") : false;

    let clicked = null;

    if (FORM && INPUT && ELEMS.length > 0) {
        toggle.addEventListener("click", (e) => {
            e.preventDefault();

            clicked = toggle;

            FORM.classList.add("is-active");
            INPUT.focus();
        }, { passive: false });

        ELEMS.forEach((element) => {
            element.addEventListener("blur", () => {
                setTimeout(() => {
                    if (!FORM.contains(document.activeElement)) {
                        FORM.classList.remove("is-active");

                        if (clicked) {
                            clicked.focus();
                        } else {
                            TOGGLES[0].focus();
                        }
                    }
                }, 100);
            }, { passive: true });
        });
    }
});
