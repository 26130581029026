// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const UPDATE = () => {
    if (!document.documentElement.classList.contains("is-scrolled") && window.scrollY >= 25) {
        document.documentElement.classList.add("is-scrolled");
    } else if (document.documentElement.classList.contains("is-scrolled") && window.scrollY < 25) {
        document.documentElement.classList.remove("is-scrolled");
    }
};

window.addEventListener("scroll", UPDATE);
document.addEventListener("DOMContentLoaded", UPDATE);
