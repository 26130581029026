// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper from "swiper";

const BUTTONS = document.querySelectorAll("[data-slide]");

/**
 * Activate targetted button
 */
const SET_ACTIVE = (targets) => {
    /**
     * Reset existing
     */
    BUTTONS.forEach((button) => {
        button.classList.remove("button--active");
    });

    /**
     * Activate target
     */
    targets.forEach((target) => {
        target.classList.add("button--active");
    });
};

/**
 * Initialize Swiper
 */
const SWIPER = new Swiper(".swiper-container--hero", {
    autoplay: {
        delay: 3000,
    },
    loop: true,
    on: {
        slideChange: function () { // eslint-disable-line
            /**
             * NOTE: Cannot use arrow function because `this` must be accessible
             */
            SET_ACTIVE(document.querySelectorAll(`[data-slide="${this.realIndex + 1}"]`));
        },
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    speed: 150,
});

/**
 * slideTo matching index on button click
 */
if (BUTTONS.length > 0) {
    BUTTONS.forEach((button) => {
        button.addEventListener("click", () => {
            SWIPER.slideTo(button.dataset.slide);
        }, { passive: true });
    });
}
